<template>
  <table>
    <thead>
      <tr>
        <th colspan="5" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>英語能力檢定</span>
            <v-icon
              v-if="!isEditMode && (isAdmin && false)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin && false"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>測驗名稱</th>
        <th>分數/等級</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr>
        <td>{{ academicData.english_test_name }}</td>
        <td>{{ academicData.english_test_score }}</td>
        <td>{{ englishTestDate }}</td>
        <td>{{ resultOptions[englishTestConfirm] }}</td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.english_test_note
              ? academicData.english_test_note
              : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>
          <v-select
            v-model="academicData.english_test_name"
            :items="testOptions"
            label="英文檢定項目"
            color="blue"
            item-color="blue"
            class="select-style"
          ></v-select>
        </td>
        <td><input v-model="academicData.english_test_score" /></td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="englishTestDate"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="englishTestConfirm">
            <v-radio
              v-for="n in resultOptions.length"
              :key="n"
              :label="resultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.english_test_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
const testItems = [
  '(未選擇)',
  '本校研究生線上英語課程 Level 3',
  '全民英檢(GEPT)',
  '托福紙筆測驗(ITP TOEFL)',
  '電腦化托福測驗(CBT TOEFL)',
  '網路化托福測驗(iBT TOEFL)',
  '雅思(IELTS)',
  '多益測驗(TOEIC)',
];
export default {
  data() {
    return {
      isEditMode: false,
      testOptions: testItems,

      resultOptions: ['警告', '通過'],
      // score: null,
      // englishTestResult: null,
      // datePicked: '',
      // testName: testItems[0],
      // note: '',
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
    englishTestConfirm: {
      get: function() {
        return this.academicData.english_test_confirm ? 1 : 0;
      },
      set: function(value) {
        this.academicData.english_test_confirm = value === 1 ? true : false;
      },
    },
    englishTestDate: {
      get: function() {
        return moment(this.academicData.english_test_date).format('YYYY-MM-DD');
      },
      set: function(value) {
        this.academicData.english_test_date =
          moment(value).format('YYYY-MM-DDT23:59:59') + 'Z';
      },
    },
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDate(date) {
      this.englishTestDate = date;
    },
    async saveEdit() {
      if (this.academicData.english_test_name === '(未選擇)') {
        this.academicData.english_test_name = '';
        return;
      }

      const englishTest = {
        english_test_name: this.academicData.english_test_name,
        english_test_score: this.academicData.english_test_score,
        english_test_date: this.academicData.english_test_date,
        english_test_confirm: this.academicData.english_test_confirm,
        english_test_note: this.academicData.english_test_note,
      };

      await this.$services.academic.saveEnglishTest(
        'phd',
        this.academicData.uid,
        englishTest,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
.select-style {
  width: 150px;
}
</style>

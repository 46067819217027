<template>
  <table>
    <thead>
      <tr>
        <th colspan="6" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>資格考學科考試</span>
            <v-icon
              v-if="!isEditMode && (isAdmin && false)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin && false"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>科目</th>
        <th>通過方式</th>
        <th class="paper-note">分數 / 論文名稱</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr v-for="course in academicData.qualify_subject_exam" :key="course.cid">
        <td>{{ course.subject_name }}</td>
        <td>{{ course.pass_type }}</td>
        <td>
          {{ course.note }}
        </td>
        <td>
          {{ course.date }}
        </td>
        <td>{{ resultOptions[course.confirm] }}</td>
      </tr>
      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.qualify_subject_note
              ? academicData.qualify_subject_note
              : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(course, idx) in academicData.qualify_subject_exam"
        :key="course.cid"
      >
        <td>
          <v-select
            v-model="course.note"
            :items="courseNameList"
            label="科目"
            color="blue"
            item-color="blue"
            class="select-style"
          ></v-select>
        </td>
        <td>
          <v-select
            v-model="course.pass_type"
            :items="passWayList"
            label="通過方式"
            color="blue"
            item-color="blue"
            class="select-style"
          ></v-select>
        </td>
        <td>
          <div class="note-textarea">
            <textarea
              placeholder="請輸入論文名稱或分數"
              v-model="course.paperDetail"
            />
          </div>
        </td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="course.date"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="course.confirm">
            <v-radio
              v-for="n in academicData.qualify_subject_exam.length"
              :key="n"
              :label="resultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.qualify_subject_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
// const courseNameList = [
//   '高等資料庫管理',
//   '分散式系統',
//   '資訊科技策略研究',
//   '資訊科技與組織研究',
//   '數位內容技術',
//   '知識管理',
//   '電子化企業',
//   '電子商務',
//   '高等資訊管理',
//   '高等計算機網路',
//   '資料探勘與管理',
//   '策略與組織研究',
//   '網路與系統',
//   '管理資訊系統',
// ];
const courseNameList = [
  '資料探勘與管理',
  '策略與組織研究',
  '網路與系統',
  '管理資訊系統',
];
const passWayList = ['考試通過', '論文抵免'];
export default {
  data() {
    return {
      isEditMode: false,
      courseNameList: courseNameList,
      passWayList: passWayList,
      resultOptions: ['警告', '通過'],
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDate(date, idx) {
      this.academicData.qualify_subject_exam[idx].date = date;
    },
    async saveEdit() {
      const qualifyExamList = this.academicData.qualify_subject_exam.map(
        item => {
          return {
            cid: item.cid,
            note: item.note,
            pass_type: item.pass_type === '考試通過' ? 'exam' : 'paper',
            date: moment(item.date).format('YYYY-MM-DDT23:59:59') + 'Z',
            confirm:
              item.confirm == undefined
                ? undefined
                : item.confirm === 1
                ? true
                : false,
          };
        },
      );
      const qualifyExam = {
        qualify_subjects: qualifyExamList,
        qualify_subject_note: this.academicData.qualify_subject_note,
      };

      await this.$services.academic.saveQualifiedExam(
        this.academicData.uid,
        qualifyExam,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/academy.scss';
</style>

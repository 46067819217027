<template>
  <table>
    <thead>
      <tr>
        <th colspan="3" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>成果發表會</span>
            <v-icon
              v-if="!isEditMode && (isAdmin && false)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin && false"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>論文題目</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr>
        <td>
          {{
            academicData.presentation_subject
              ? academicData.presentation_subject
              : '/'
          }}
        </td>
        <td>{{ presentationDate }}</td>
        <td>{{ overallResultOptions[presentationConfirm] }}</td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.presentation_note
              ? academicData.presentation_note
              : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td>
          <div class="note-textarea">
            <textarea
              placeholder="請輸入論文題目"
              v-model="academicData.presentation_subject"
            />
          </div>
        </td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="presentationDate"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="presentationConfirm">
            <v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.presentation_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,
      paperResultOptions: ['不通過', '通過'],
      overallResultOptions: ['警告', '通過'],

      overallResult: '',
      paperDetail: '',
      datePicked: '',
      note: '',
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
    presentationConfirm: {
      get: function() {
        return this.academicData.presentation_confirm == undefined
          ? undefined
          : this.academicData.presentation_confirm == false
          ? 0
          : 1;
      },
      set: function(value) {
        this.academicData.presentation_confirm = value === 1 ? true : false;
      },
    },
    presentationDate: {
      get: function() {
        return moment(this.academicData.presentation_date).format('YYYY-MM-DD');
      },
      set: function(value) {
        this.academicData.presentation_date =
          moment(value).format('YYYY-MM-DDT23:59:59') + 'Z';
      },
    },
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDate(date) {
      this.presentationDate = date;
    },
    async saveEdit() {
      const presentation = {
        presentation_subject: this.academicData.presentation_subject,
        presentation_date: this.academicData.presentation_date,
        presentation_confirm: this.academicData.presentation_confirm,
        presentation_note: this.academicData.presentation_note,
      };
      await this.$services.academic.savePresentation(
        this.academicData.uid,
        presentation,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
.select-style {
  width: 100px;
}
</style>

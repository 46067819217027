<template>
  <div id="profile-page">
    <loading
      color="var(--v-blue-base)"
      loader="dots"
      :active.sync="isLoading"
      :is-full-page="true"
    />
    <div class="page-title">個人資料</div>
    <div class="profile-public-container">
      <div class="profile-pic-container">
        <img :src="this.photo" :onerror="`this.src='${this.defaultProfile}'`" />
        <v-btn large solo disabled> 個人照片 </v-btn>
      </div>
      <div v-if="!isPublicEditing" class="profile-public-info-container">
        <div class="profile-public-label elevation-3">公開資料</div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label">姓名</div>
            <div class="item-content">{{ publicProfile.name }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">學號</div>
            <div class="item-content">{{ publicProfile.uid }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">身份</div>
            <div class="item-content">{{ auth }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">年級</div>
            <div class="item-content">{{ grade }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">導師</div>
            <div class="item-content">
              {{
                (teacherList.filter(
                  (x) => x.key == publicProfile.mentor_uid,
                )[0] || {})['text']
              }}
              老師
            </div>
          </div>
        </div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label item-label-longer">入學年度</div>
            <div class="item-content">{{ entryTime }}</div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">離校年度</div>
            <div class="item-content">
              {{ leaveTime }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">畢業論文</div>
            <div class="item-content">{{ publicProfile.graduate_paper }}</div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">電子郵件</div>
            <div class="item-content">
              <a :href="'mailto:' + publicProfile.email">
                {{ publicProfile.email }}
              </a>
            </div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">個人網頁</div>
            <div class="item-content">
              <a :href="publicProfile.website">{{ publicProfile.website }}</a>
            </div>
          </div>
        </div>
        <div
          class="edit-icon-container"
          @click="editPublicProfile"
          v-if="isAdmin || isTeacher || currentUID === publicProfile.uid"
        >
          <v-icon small color="#FFFFFF" class="edit-btn"> mdi-pencil </v-icon>
        </div>
      </div>
      <div v-else class="profile-public-info-container">
        <div class="profile-public-label">公開資料</div>
        <div class="public-info">
          <div class="info-item-edit">
            <div class="item-label">姓名</div>
            <input
              v-if="isAdmin"
              class="info-item-input"
              v-model="publicProfile.name"
            />
            <div v-else class="item-content">{{ publicProfile.name }}</div>
          </div>
          <div class="info-item-edit">
            <div class="item-label">學號</div>
            <!-- <input class="info-item-input" v-model="publicProfile.uid" /> -->
            <div class="item-content">{{ publicProfile.uid }}</div>
          </div>
          <div class="info-item-edit">
            <div class="item-label">身份</div>
            <v-select
              solo
              flat
              dense
              v-if="isAdmin"
              hide-details
              v-model="publicProfile.auth"
              :items="this.authList"
              label="身份"
              color="blue"
              item-color="blue"
              class="info-item-edit-select"
            />
            <div v-else class="item-content">{{ auth }}</div>
          </div>
          <div class="info-item-edit">
            <div class="item-label">年級</div>
            <!-- <input v-if="isAdmin" class="info-item-input" v-model="grade" /> -->
            <div class="item-content">{{ grade }}</div>
          </div>
          <div class="info-item-edit">
            <div class="item-label">導師</div>
            <div v-if="!isAdmin && !isTeacher" class="item-content">
              {{
                (teacherList.filter(
                  (x) => x.key == publicProfile.mentor_uid,
                )[0] || {})['text']
              }}
              老師
            </div>
            <v-select
              solo
              flat
              dense
              v-else
              hide-details
              v-model="publicProfile.mentor_uid"
              :items="this.teacherList"
              item-text="text"
              item-value="key"
              label="導師"
              color="blue"
              item-color="blue"
              class="info-item-edit-select"
            ></v-select>
          </div>
        </div>
        <div class="public-info">
          <div class="info-item-edit">
            <div class="item-label item-label-date">入學年度</div>
            <div v-if="!isAdmin" class="item-content">{{ entryTime }}</div>
            <div v-else class="date-picker">
              <DatePicker
                class="date-picker"
                color="blue"
                @saveDate="saveEntryDate"
                :datePicked="entryTime"
                :isIcon="false"
              />
            </div>
          </div>
          <div class="info-item-edit">
            <div class="item-label item-label-date">離校年度</div>
            <div v-if="!isAdmin" class="item-content">
              {{ leaveTime }}
            </div>
            <div v-else class="date-picker">
              <DatePicker
                class="date-picker"
                color="blue"
                @saveDate="saveLeaveDate"
                :datePicked="leaveTime"
                :isIcon="false"
              />
            </div>
          </div>
          <div class="info-item-edit">
            <div class="item-label item-label-longer">畢業論文</div>
            <div class="item-content"></div>
          </div>
          <div class="info-item-edit">
            <div class="item-label item-label-longer">電子郵件</div>
            <input class="info-item-input" v-model="publicProfile.email" />
          </div>
          <div class="info-item-edit">
            <div class="item-label item-label-longer">個人網頁</div>
            <input class="info-item-input" v-model="publicProfile.website" />
          </div>
        </div>
        <div
          v-if="!isPublicEditing"
          class="edit-icon-container"
          @click="editPublicProfile"
        >
          <v-icon small color="#FFFFFF" class="edit-btn"> mdi-pencil </v-icon>
        </div>
        <div v-else class="save-icon-container">
          <v-icon
            small
            color="white"
            class="finish-edit-btn"
            @click="savePublicEdit"
          >
            mdi-check-bold
          </v-icon>
        </div>
      </div>
    </div>

    <div
      v-if="
        !isPrivateEditing &&
        (isAdmin || isTeacher || currentUID === publicProfile.uid)
      "
      class="profile-private-container"
    >
      <div class="profile-private-label elevation-3">個人隱藏資料</div>

      <div class="info-item private-info-item">
        <div class="item-label private-item-label">電話</div>
        <div class="item-content private-content">
          {{ privateProfile.phone }}
        </div>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">手機</div>
        <div class="item-content private-content">
          {{ privateProfile.cellphone }}
        </div>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">住址</div>
        <div class="item-content private-content">
          {{ privateProfile.address }}
        </div>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">修業狀況</div>
        <div class="pl-4">
          <v-btn
            color="var(--v-blue-base)"
            v-if="['bba', 'mba', 'phd'].includes(publicProfile.grade)"
            class="private-content link-color"
            @click="goToAcademyPage"
            small
          >
            {{ publicProfile.grade == 'bba' ? '學校導生綜合資料系統' : '查看' }}
          </v-btn>
          <span class="ml-2 darkGrey2--text">{{
            publicProfile.grade == 'bba'
              ? '請導師以臺大計中帳號密碼登入查詢'
              : ''
          }}</span>
        </div>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label-higher private-item-label">
          升學狀況
        </div>
        <div class="item-content private-item-label-higher private-content">
          {{ privateProfile.education }}
        </div>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label-higher private-item-label">
          就業狀況
        </div>
        <div class="item-content private-item-label-higher private-content">
          {{ privateProfile.job }}
        </div>
      </div>
      <div
        v-if="!isPrivateEditing"
        class="edit-icon-container"
        @click="editPrivateProfile"
      >
        <v-icon small color="#FFFFFF" class="edit-btn"> mdi-pencil </v-icon>
      </div>
      <div v-else class="save-icon-container">
        <v-icon
          small
          color="white"
          class="finish-edit-btn"
          @click="savePrivateEdit"
        >
          mdi-check-bold
        </v-icon>
      </div>
    </div>

    <div
      v-else-if="isAdmin || isTeacher || currentUID === publicProfile.uid"
      class="profile-private-container"
    >
      <div class="profile-private-label elevation-3">個人隱藏資料</div>

      <div class="info-item private-info-item">
        <div class="item-label private-item-label">電話</div>
        <input class="info-item-input" v-model="privateProfile.phone" />
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">手機</div>
        <input class="info-item-input" v-model="privateProfile.cellphone" />
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">住址</div>
        <input class="info-item-input" v-model="privateProfile.address" />
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label">修業狀況</div>
        <span class="private-content grey-color-text pl-4"> 查看 </span>
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label-higher private-item-label">
          升學狀況
        </div>
        <textarea
          class="info-item-textarea"
          v-model="privateProfile.education"
        />
      </div>
      <div class="info-item private-info-item">
        <div class="item-label private-item-label-higher private-item-label">
          就業狀況
        </div>
        <textarea class="info-item-textarea" v-model="privateProfile.job" />
      </div>
      <div
        v-if="!isPrivateEditing"
        class="edit-icon-container"
        @click="editPrivateProfile"
      >
        <v-icon small color="#FFFFFF" class="edit-btn"> mdi-pencil </v-icon>
      </div>
      <div v-else class="save-icon-container">
        <v-icon
          small
          color="white"
          class="finish-edit-btn"
          @click="savePrivateEdit"
        >
          mdi-check-bold
        </v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import config from '../../config';
import { mapGetters } from 'vuex';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DatePicker from '@/components/DataPicker.vue';
export default {
  name: 'profile',
  data() {
    return {
      defaultProfile: require('../assets/defaultProfile.jpg'),
      publicProfile: {},
      privateProfile: {},
      teacherOptions: [],
      isPublicEditing: false,
      isPrivateEditing: false,
      teacherList: [],
      authList: [
        { value: 'admin', text: '管理員' },
        { value: 'teacher', text: '老師' },
        { value: 'student', text: '學生' }
      ],

      uid: '',
    };
  },
  components: {
    DatePicker,
    Loading,
  },
  computed: {
    ...mapGetters([
      'currentUID',
      'isAdmin',
      'isTeacher',
      'currentType',
      'currentAuth',
      'warningText',
      'isLoading',
    ]),
    entryTime: function () {
      return this.publicProfile.entry_time
        ? moment(this.publicProfile.entry_time).format('YYYY-MM-DD')
        : '';
    },
    leaveTime: function () {
      return this.publicProfile.leave_time
        ? moment(this.publicProfile.leave_time).format('YYYY-MM-DD')
        : '';
    },
    grade: function () {
      return this.gradeMapping(this.publicProfile);
    },
    auth: function () {
      const authMap = {
        admin: '管理員',
        teacher: '老師',
        student: '學生',
      };
      return authMap[this.publicProfile.auth];
    },
    photo() {
      return `${config.BASEURL}/image/${this.publicProfile.photo}`;
    },
  },
  methods: {
    goToAcademyPage() {
      if (this.publicProfile.grade == 'mba') {
        this.$router.push(`/Master/${this.uid}`);
      } else if (this.publicProfile.grade == 'phd') {
        this.$router.push(`/Doctor/${this.uid}`);
      } else if (this.publicProfile.grade == 'bba') {
        location = `https://my.ntu.edu.tw/stuinfo/staffportal.aspx?reg_no=${this.publicProfile.uid}`;
      }
    },
    gradeMapping(profile) {
      const typeMap = {
        bba: '學士班',
        mba: '碩士班',
        phd: '博士班',
      };
      const currentType = typeMap[profile.grade];
      const dates = moment().diff(moment(profile.entry_time), 'days');
      const grade = Math.floor(dates / 365) + 1;
      if (currentType)
        return profile.leave_time ? '已畢業' : `${currentType} ${grade} 年級`;
      return '-';
    },
    async getProfile() {
      const publicProfile = await this.$services.profile.getPublicProfile(
        this.uid,
      );
      this.publicProfile = publicProfile;
      if (
        this.isAdmin ||
        this.isTeacher ||
        this.currentUID === this.publicProfile.uid
      ) {
        const privateProfile = await this.$services.profile.getPrivateProfile(
          this.uid,
        );
        this.privateProfile = privateProfile;
      }
    },
    editPublicProfile() {
      this.isPublicEditing = !this.isPublicEditing;
    },
    editPrivateProfile() {
      this.isPrivateEditing = !this.isPrivateEditing;
    },
    async savePublicEdit() {
      this.publicProfile.entry_time = moment(
        this.publicProfile.entry_time,
      ).format('YYYY-MM-DD');
      this.publicProfile.leave_time = moment(
        this.publicProfile.leave_time,
      ).format('YYYY-MM-DD');

      await this.$services.profile.saveProfile(
        this.publicProfile.uid,
        this.publicProfile,
      );
      if (!this.warningText) {
        this.isPublicEditing = !this.isPublicEditing;
      }
    },
    async savePrivateEdit() {
      await this.$services.profile.saveProfile(
        this.publicProfile.uid,
        this.privateProfile,
      );
      if (!this.warningText) {
        this.isPrivateEditing = !this.isPrivateEditing;
      }
    },
    saveEntryDate(date) {
      this.publicProfile.entry_time = date;
    },
    saveLeaveDate(date) {
      this.publicProfile.leave_time = date;
    },
    async getTeachers() {
      var result = await this.$services.profile.getTeacherOptions(
        { grade: 'teacher' },
        0,
        -1,
      );
      this.teacherList = result.data.map((x) => ({ key: x.uid, text: x.name }));
    },
  },
  mounted() {
    if (!this.$route.params.id)
      this.$router.replace(`/Profile/${this.currentUID}`);
    this.uid = this.$route.params.id;
    this.getProfile();
    this.getTeachers();
  },
};
</script>

<style lang="scss" scoped>
#profile-page {
  padding: 33px 15% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
}
.page-title {
  margin: 0 0 10px 0;
  color: var(--v-pageTitle-base);
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}
.profile-public-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: auto;
  min-height: 300px;
  margin-top: 30px;
}
.profile-pic-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 25px;
  flex-grow: 1;
  flex-basis: 0%;

  height: 100%;
  max-height: 350px;
}

.profile-pic-container > img {
  min-width: 190px;
  width: 100%;
  height: 230px;
  border-radius: 24px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  object-fit: cover;
  margin-bottom: 17px;
}

.upload-btn {
  color: var(--v-white-base);
  font-weight: bold;
  width: 100%;
  min-width: 180px;
  height: 40px;
  background-color: var(--v-green-base);
  border-radius: 12px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  margin-top: 22px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile-public-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 4;

  background-color: var(--v-white-base);
  height: 100%;
  border-radius: 24px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 40px 35px;

  position: relative;
}

.profile-public-label {
  width: 160px;
  height: 40px;
  position: absolute;
  top: -20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--v-blue-base);
  color: var(--v-white-base);
}

.public-info {
  width: 100%;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.info-item {
  width: 100%;
  height: 40px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  /* background-color: yellow; */
}

.item-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 35px;
  color: var(--v-white-base);
  background-color: var(--v-blue-base);
  border-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.item-content {
  display: flex;
  background: rgba(200, 200, 200, 0.15);
  padding: 0 16px;
  margin-right: 16px;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 35px;
  align-items: center;
  font-weight: bold;
  color: var(--v-darkGrey2-base);
  text-align: left;
  width: 100%;
  word-break: break-all;
  ::v-deep .v-input__control .v-input__slot {
    padding: 0 12px 0 0 !important;
  }
}

.edit-icon-container {
  width: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--v-red-base);

  box-shadow: 0.1px 0.1px 3px var(--v-darkGrey2-base);
}
.save-icon-container {
  width: 35px;
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--v-green-base);

  box-shadow: 0.1px 0.1px 3px var(--v-darkGrey2-base);
}
.save-icon-container:hover,
.edit-icon-container:hover {
  box-shadow: 3px 3px 5px var(--v-darkGrey2-base);
  cursor: pointer;
}

.edit-icon {
  width: 100%;
}

.profile-private-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  background-color: var(--v-white-base);
  width: 100%;
  /* height: auto; */
  border-radius: 24px;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 40px 35px;
  margin-top: 45px;

  position: relative;
}

.profile-private-label {
  width: 200px;
  height: 40px;
  position: absolute;
  top: -20px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--v-pink-base);
  color: var(--v-white-base);
}

.private-info-item {
  margin: 5px 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .info-item-input {
    width: 100%;
    height: 35px;
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid var(--v-lightGrey-base);
    border-radius: 5px;
    padding: 5px;
    color: var(--v-darkGrey-base);
    &:focus {
      border: 2px solid var(--v-blue-base);
      color: var(--v-darkGrey-base);
    }
  }

  .info-item-textarea {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid var(--v-lightGrey-base);
    border-radius: 5px;
    padding: 5px;
    color: var(--v-darkGrey-base);
    resize: none;
    &:focus {
      border: 2px solid var(--v-blue-base);
      color: var(--v-darkGrey-base);
    }
  }
}

.private-item-label-higher {
  height: 70px;
}

.private-item-label {
  background-color: var(--v-pink-base);
  width: 100%;
  max-width: 100px;
}
.private-content {
}

.link-color {
  color: var(--v-white-base);
  cursor: pointer;

  // text-decoration: underline;
}
.grey-color-text {
  color: var(--v-darkGrey-base);
}

.info-item-edit {
  width: 100%;
  height: 40px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .info-item-input {
    width: 100%;
    height: 36px;
    margin-right: 16px;
    margin-left: 10px;
    border: 2px solid var(--v-lightGrey-base);
    border-radius: 5px;
    padding: 5px;
    color: var(--v-darkGrey-base);
    // outline: none;
    &:focus {
      border: 2px solid var(--v-blue-base);
      color: var(--v-darkGrey-base);
    }
  }
  .item-label-date {
    margin-right: 10px;
  }
  .date-picker {
    width: 100%;
    padding-right: 16px;
  }
}
.info-item-edit-select {
  ::v-deep .v-input__slot {
    border: 2px solid var(--v-lightGrey-base);
    border-radius: 5px;
    .v-select__selection {
      color: var(--v-darkGrey-base);
    }
  }
  margin: 0 16px 0 10px;
  width: 0;
}
</style>

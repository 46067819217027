<template>
  <table>
    <thead>
      <tr>
        <th colspan="4" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>論文發表點數</span>
            <v-icon
              v-if="!isEditMode && (isAdmin && false)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin && false"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>點數</th>
        <th>論文名稱</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr v-for="(item, idx) in academicData.paper_points" :key="idx">
        <td>{{ item.point }}</td>
        <td>{{ item.paper_name ? item.paper_name : '/' }}</td>
        <td>{{ item.date }}</td>
        <td>{{ overallResultOptions[item.confirm] }}</td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.paper_point_note ? academicData.paper_point_note : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="(item, idx) in academicData.paper_points" :key="idx">
        <td>
          <v-select
            v-model="item.point"
            :items="paperPointsList"
            label="點數"
            color="blue"
            item-color="blue"
            class="select-style"
          ></v-select>
        </td>
        <td>
          <div class="note-textarea">
            <textarea placeholder="請輸入論文名稱" v-model="item.paper_name" />
          </div>
        </td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="item.date"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="item.confirm">
            <v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>

      <tr>
        <td colspan="5">
          <v-icon small color="#FFFFFF" class="plus-btn" @click="addProposal">
            mdi-plus
          </v-icon>
        </td>
      </tr>

      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.paper_point_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    tableTitle: { type: String, default: '' },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,
      paperResultOptions: ['不通過', '通過'],
      overallResultOptions: ['警告', '通過'],
      paperPointsList: ['0.5', '1', '2', '3'],

      // paperPoint: 0,
      // overallResult: '',
      // paperName: '',
      // datePicked: '',
      // note: '',
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    addProposal() {
      this.academicData.paper_points.push({
        paper_name: '',
        point: '',
        date: moment().format('YYYY-MM-DD'),
        confirm: undefined,
      });
    },
    saveDate(date, idx) {
      this.academicData.paper_points[idx].date = date;
    },
    async saveEdit() {
      this.academicData.paper_points = this.academicData.paper_points.filter(
        point => point.paper_name,
      );
      const pointList = this.academicData.paper_points.map(point => {
        return {
          paper_name: point.paper_name,
          point: point.point,
          date: moment(point.date).format('YYYY-MM-DDT23:59:59') + 'Z',
          confirm:
            point.confirm == undefined
              ? undefined
              : point.confirm === 1
              ? true
              : false,
        };
      });
      const point = {
        paper_points: pointList,
        degree_point_note: this.academicData.paper_point_note,
      };
      await this.$services.academic.savePaperPoints(
        this.academicData.uid,
        point,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
.select-style {
  width: 100px;
}
</style>
